<template>
    <div v-show="clientInfo.hasAudit === 0" class="pb-5 flex flex-col w-1/2 ml-12" style="width: 400px; height: 580px">
        <header class="text-header-blue text-xl font-semibold mb-3">Revision</header>
        <el-form-item class="w-full" label="Revisionsbyrå namn" prop="auditFirmName">
            <el-input v-model="auditData.auditingFirmName" />
        </el-form-item>
        <el-form-item class="w-full" label="Förnamn" prop="firstName">
            <el-input v-model="auditData.auditorFirstName" />
        </el-form-item>
        <el-form-item class="w-full" label="Efternamn" prop="lastName">
            <el-input v-model="auditData.auditorLastName" />
        </el-form-item>
        <el-form-item class="w-full" label="Personnummer" prop="socialSecurityNumber">
            <el-input v-model="auditData.identificationNumber" />
        </el-form-item>
        <el-form-item class="w-full" label="Titel" prop="title">
            <el-select class="w-full" v-model="auditData.auditorTitle" placeholder="Välj">
                <el-option v-for="title in selectAuditorTitle" :label="title.name" :value="title.name" :key="title.id" />
            </el-select>
        </el-form-item>
        <el-form-item>
            <RadioButtonList :entries="signatureTextAuditor" v-model="auditData.signatureText" :horizontal="false" label="Text på sidan underskrifter i årsredovisningen" />
        </el-form-item>
    </div>
</template>

<script>
export default {
    components: {
        RadioButtonList: () => import("../../../../components/content/RadioButtonList.vue"),
    },
    props: {
        clientInfo: {
            type: Object,
        },
        auditData: {
            type: Object,
        },
        selectAuditorTitle: {
            type: Array,
        },
        signatureTextAuditor: {
            type: Array,
        },
    },
    data() {
        return {
            selectedAuditorTitle: "",
        };
    },
};
</script>
